<template>
    <AppPanel :location="['Importação']" subtitle="Importações">
        <template #content>
            <div class="">
                <AppPaginatedGrid ref="grid" :service="service" :actionItems="actionItems"
                    :filtrosExtras="filtrosExtras" newPath="/importacao/new" editPath="/importacao/"
                    @onToggleMenu="onToggleMenu" subtitle="Importação" tooltip="Sincronização com SOC"
                    nomeTelaDoManual="importacao-list" origemHistoricoAcao="/importacao">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <Button label="Processar minhas importações" icon="pi pi-check" class="p-button-success"
                                    rounded outlined style="margin: 0px 5px" @click="processarPendentes" />
                                <Button label="Excluir finalizadas" icon="pi pi-check" class="p-button-danger"
                                    style="margin: 0px 5px" rounded outlined @click="excluirFinalizadas" />
                                <Button label="Limpar importações" icon="pi pi-check" class="" style="margin: 0px 5px"
                                    rounded outlined @click="excluirTodos" />
                                <Button label="Atualizar" icon="pi pi-refresh" class="p-button-info" rounded
                                    style="margin: 0px 5px" @click="load" />
                            </div>
                            <div class="col-4">
                                <Checkbox class="" id="auto-load" v-model="filtrosExtras.minhasImportacoes"
                                    :binary="true" />
                                <label class="ml-2 mb-2" for="auto-load">Minhas importações</label>
                                <Checkbox class="ml-2" id="auto-load" v-model="filtrosExtras.exibirFinalizadas"
                                    :binary="true" />
                                <label class="ml-2 mb-2" for="auto-load">Finalizadas</label>
                            </div>
                            <div v-if="totais.percentual" class="col-3">
                                <label class="ml-2 mb-2" for="auto-load"><b>Progresso: {{
        Math.round(totais.percentual) }} % </b></label>
                            </div>
                        </div>
                    </template>
                    <template #columns>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button :disabled="loadItemsProcessar.length > 0 ||
        slotProps.data.status == 'FINALIZADA' ||
        slotProps.data.status == 'CANCELADA' ||
        slotProps.data.status == 'PROCESSANDO' ||
        slotProps.data.fila == true ||
        !slotProps.data.status
        " :loading="!!loadItemsProcessar[loadItemsProcessar.indexOf(slotProps.data.id)]" label="Processar"
                                    icon="pi pi-check" class="p-button-success" style="margin: 0px 5px"
                                    @click="processar($event, slotProps.data)" />
                            </template>
                        </Column>

                        <!-- V-IF = FALSE: COLOCAR TRUE APENAS PARA TESTE LOCAL -->
                        <Column v-if="false" bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button :disabled="loadItemsBaixar.length > 0 || slotProps.data.status != 'CRIADA'"
                                    :loading="!!loadItemsBaixar[loadItemsBaixar.indexOf(slotProps.data.id)]"
                                    label="Baixar" icon="pi pi-check" class="p-button-success" style="margin: 0px 5px"
                                    @click="baixarDados($event, slotProps.data)" />
                            </template>
                        </Column>

                        <!-- V-IF = FALSE: COLOCAR TRUE APENAS PARA TESTE LOCAL -->
                        <Column v-if="false" bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button
                                    :disabled="loadItemsImportar.length > 0 || slotProps.data.etapa != 'DADOS_BAIXADOS'"
                                    :loading="!!loadItemsImportar[loadItemsImportar.indexOf(slotProps.data.id)]"
                                    label="Importar" icon="pi pi-check" class="p-button-success" style="margin: 0px 5px"
                                    @click="importar($event, slotProps.data)" />
                            </template>
                        </Column>

                        <Column :sortable="true" field="id" sortField="importacao.id" header="Código"></Column>
                        <Column :sortable="true" field="cliente.name" header="Cliente"></Column>
                        <Column :sortable="true" field="status" header="Status"></Column>
                        <Column :sortable="true" field="etapa" header="Etapa"></Column>
                        <Column :sortable="true" field="erro" header="Mensagem"></Column>
                        <Column :sortable="true" field="origem" header="Origem"></Column>
                        <Column :sortable="true" field="registrosInseridos" header="Inseridos"></Column>
                        <Column :sortable="true" field="registrosErro" header="Erro"></Column>
                        <Column :sortable="true" field="total" header="Total"></Column>
                        <Column :sortable="true" field="percentual" header="Percentual"></Column>
                    </template>
                </AppPaginatedGrid>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import { showError } from '../../utils/Toast';

export default {
    data() {
        return {
            autoRefresh: false,
            autoload: false,
            service: null,
            record: {},
            totais: {},
            filtrosExtras: {
                exibirFinalizadas: false
            },
            loadItemsProcessar: [],
            loadItemsBaixar: [],
            loadItemsImportar: [],
            actionItems: [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-desktop',
                    command: () => this.editRecord(this.record)
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times-circle',
                    command: () => this.cancelar(this.record)
                },
                {
                    label: 'Voltar etapa',
                    icon: 'pi pi-undo',
                    command: () => this.voltarEtapa(this.record)
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/importacao');
    },
    watch: {
        autoload() {
            this.autoLoad();
        },
        'filtrosExtras.exibirFinalizadas'() {
            this.load(true);
        },
        'filtrosExtras.minhasImportacoes'(valor) {
            this.load(valor);
        },
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    methods: {
        async autoLoad() {
            if (!this?.$refs?.grid) {
                return;
            }

            if (!this.autoload) {
                return;
            }

            await this.load();
            await this.getTotalGeral();
            setTimeout(() => {
                this?.autoLoad && this.autoLoad();
            }, 3000);
        },
        async baixarDados($event, data) {
            data.status = '';
            try {
                this.loadItemsBaixar.push(data.id);
                await getClientBase().post('/importacao/baixar-dados/' + data.id);
                this.loadItemsBaixar = [];
                this.load();
            } catch (error) {
                this.loadItemsBaixar = [];
                showError(this.$toast, error);
            }
        },
        async processar($event, data) {
            data.status = '';
            try {
                this.loadItemsProcessar.push(data.id);
                const rota = '/importacao/processar';
                await getClientBase().post(rota, {
                    params: {
                        id: data.id
                    }
                });
                this.loadItemsProcessar = [];
                this.load();
                this.$toast.add({
                    severity: 'success',
                    summary: `Importação solicitado, ao concluir você será notificado.`,
                    life: 3000
                });
            } catch (error) {
                this.loadItemsProcessar = [];
                showError(this.$toast, error);
            }
        },
        async importar($event, data) {
            data.status = '';
            try {
                this.loadItemsImportar.push(data.id);
                await getClientBase().post('/importacao/importar-dados/' + data.id);
                this.loadItemsImportar = [];
                this.load();
            } catch (error) {
                this.loadItemsImportar = [];
                showError(this.$toast, error);
            }
        },
        async processarPendentes() {
            try {
                await getClientBase().post('/importacao/processar-pendentes/');
                this.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async excluirFinalizadas() {
            try {
                await getClientBase().delete('/importacao/excluir-finalizadas/');
                this.$toast.add({ severity: 'success', summary: 'Importações excluídas com sucesso', life: 3000 });
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao excluir: ' + message, life: 3000 });
            }
            this.load();
        },
        async excluirTodos() {
            try {
                await getClientBase().delete('/importacao/excluir-todos/');
                this.$toast.add({ severity: 'success', summary: 'Importações excluídas com sucesso', life: 3000 });
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao excluir: ' + message, life: 3000 });
            }
            this.load();
        },
        async cancelar() {
            try {
                this.record.status = '';
                await getClientBase().post('/importacao/cancelar/' + this.record.id);
                this.$toast.add({ severity: 'success', summary: 'Importação cancelada com sucesso', life: 3000 });
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao cancelar: ' + message, life: 3000 });
            }
            this.load();
        },
        async voltarEtapa() {
            try {
                this.record.status = '';
                await getClientBase().post('/importacao/voltar-etapa/' + this.record.id);
                this.$toast.add({ severity: 'success', summary: 'Importação voltada com sucesso', life: 3000 });
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao voltar: ' + message, life: 3000 });
            }
            this.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        editRecord(record) {
            this.$router.push('/importacao/' + record.id);
        },
        async getTotalGeral() {
            const responseTotais = await getClientBase().get('/importacao/total-geral/0');
            this.totais = responseTotais.data;
        },
        load(loadingEffect = false) {
            this.getTotalGeral();
            this?.$refs?.grid && this.$refs.grid.load(loadingEffect);
        }
    }
};
</script>
